<template>
  <div class="administrateurs-detail-page page-layout">
    <PageHeader
      :has-back="true"
      title="Administrateurs"
      :has-actions="true"
      @back="goBack"
    >
      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/administrators/update/' + user.id)"
      >
        Editer
      </md-button>
    </PageHeader>

    <md-card class="meep-form">
      <md-card-content>
        <md-list class="md-double-line">
          <md-list-item>
            <div class="md-list-item-text">
              <span>
                Cabinet
              </span>

              <span>
                {{ user.organization_name }}
              </span>
            </div>
          </md-list-item>

          <md-list-item>
            <div class="md-list-item-text">
              <span>
                Prénom
              </span>

              <span>
                {{ user.firstname }}
              </span>
            </div>
          </md-list-item>

          <!--nom d;admin-->
          <md-list-item>
            <div class="md-list-item-text">
              <span>
                Nom
              </span>

              <span>
                {{ user.lastname }}
              </span>
            </div>
          </md-list-item>

          <md-list-item>
            <div class="md-list-item-text">
              <span>
                Adresse e-mail
              </span>

              <span>
                {{ user.email }}
              </span>
            </div>
          </md-list-item>

          <md-list-item>
            <div class="md-list-item-text">
              <span>
                Téléphone fixe
              </span>

              <span>
                {{ user.telfixe }}
              </span>
            </div>
          </md-list-item>

          <!--telepotable d'admin-->
          <md-list-item>
            <div class="md-list-item-text">
              <span>
                Téléphone portable
              </span>

              <span>
                {{ user.telportable }}
              </span>
            </div>
          </md-list-item>

          <!--adresse d'amin-->
          <md-list-item>
            <div class="md-list-item-text">
              <span>
                Adresse
              </span>

              <span>
                {{ user.adresse }}
              </span>
            </div>
          </md-list-item>

          <!--codepostal d'admin-->
          <md-list-item>
            <div class="md-list-item-text">
              <span>
                Code postal
              </span>

              <span>
                {{ user.codepostal }}
              </span>
            </div>
          </md-list-item>

          <md-list-item>
            <div class="md-list-item-text">
              <span>
                Ville
              </span>

              <span>
                {{ user.ville }}
              </span>
            </div>
          </md-list-item>
        </md-list>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import users from "../../../model/users";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      user: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    users
      .get(to.params.id)
      .then((users) => {
        next((vm) => {
          vm.user = users;
        });
      })
      .catch(() => {
        next(from.path);
      });
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.administrateurs-detail-page {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
